import { useNavigate, useParams } from "react-router-dom";
import { Posts } from "../../components/Posts";
import { useEffect, useState } from "react";
import { AutoRefreshIcon } from "../PortfolioScanner/components/AutoRefresIcon";
import { WideLoader } from "../../components/WideLoader";
import { api } from "../../api";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet-async";

export const CompanyScanner = () => {
  const [state, setState] = useState({
    companyIds: [] as number[],
    companyName: "",
    companyTicker: "",
    isLoading: true,
  });
  const { ticker } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!ticker) return;
    (async () => {
      try {
        const company = await api.getCompany(ticker);
        document.title = ``;
        setState({
          companyIds: [company.id],
          companyName: company.name,
          companyTicker: company.ticker,
          isLoading: false,
        });
      } catch {
        setState({
          ...state,
          isLoading: false,
        });
      }
    })();
  }, [ticker]);

  if (state.isLoading) return <WideLoader />;
  if (!state.companyName && !state.isLoading) {
    navigate(`/404`);
  }

  return (
    <>
      <Helmet>
        <title>{state.companyName} – Skaner wiadomości</title>
        <meta
          name="description"
          content={`Wiadomości giełdowe na temat spółki ${state.companyName} (${state.companyTicker}) z kilkudziesięciu różnych źródeł, czyli znacznie więcej niż ma Bankier lub BiznesRadar.`}
        />
      </Helmet>
      <div className="w-full border-b border-gray-100 py-4">
        <div className="mx-auto flex max-w-5xl px-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="mt-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
              {state.companyName}
            </h1>
          </div>
          <div className="ml-auto mt-2 min-w-28 text-right">
            <AutoRefreshIcon />
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-5xl px-4 py-[2.125rem] sm:px-6 lg:px-8">
        {!state.isLoading && state.companyIds.length > 0 && (
          <Posts companyIds={state.companyIds} showCompanyNames={false}>
            <div className="my-16 text-center">
              <ArchiveBoxXMarkIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">
                Brak wiadomości dla wybranej spółki
              </h3>
            </div>
          </Posts>
        )}
      </div>
    </>
  );
};
