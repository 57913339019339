import axios, { AxiosError } from "axios";

export const axiosApi = axios.create({
  withCredentials: true,
  baseURL: "/api/public/",
});

const errorHandler = (error: AxiosError) => {
  const statusCode = error.response?.status;

  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

axiosApi.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
