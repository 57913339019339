import { ReactElement } from "react";

export const intersperse = (arr: ReactElement[], sep: ReactElement) => {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    function (xs, x) {
      return xs.concat([sep, x]);
    },
    [arr[0]],
  );
};
