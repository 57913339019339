import { useRecoilState } from "recoil";
import { Company } from "../../../entities/company";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { portfolioScannerConfigAtom } from "../../../atoms/portfolioScannerConfigAtom";

export const SelectedCompanies = () => {
  const [configState, setConfigState] = useRecoilState(
    portfolioScannerConfigAtom,
  );

  const remove = (company: Company) => {
    setConfigState({
      ...configState,
      selectedCompanies: configState.selectedCompanies.filter(
        (x) => x.id !== company.id,
      ),
    });
  };

  return (
    <nav aria-label="Sidebar" className="flex flex-1 flex-col p-2">
      <ul className="-mx-2 space-y-1">
        {configState.selectedCompanies.map((company) => (
          <li key={company.name} onClick={() => remove(company)}>
            <span className="group flex w-full cursor-pointer content-center gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600">
              {company.name}
              <XMarkIcon
                aria-hidden="true"
                className="ml-auto h-5 w-5 border-t-4 border-solid border-white"
              />
            </span>
          </li>
        ))}
      </ul>
    </nav>
  );
};
