import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useRecoilState } from "recoil";
import { ComplaintType } from "../enums/complaint-type";
import { useEffect, useState } from "react";
import { api } from "../api";
import { useNotifications } from "../hooks/useNotifications";
import { NotificationType } from "../enums/notification-type";
import { complaintDrawerAtom } from "../atoms/complaintDrawerAtom";

class ComplaintDrawerState {
  askDescription: boolean = false;
  askLink: boolean = false;
  title: string = "";
  description: string = "";
  successMessage: string = "";
  linkId?: number;
}

export const ComplaintDrawer = () => {
  const [globalState, setGlobalState] = useRecoilState(complaintDrawerAtom);
  const [state, setState] = useState(new ComplaintDrawerState());
  const { setNotification } = useNotifications();

  useEffect(() => {
    if (!globalState.showComplaintDrawer) return;

    switch (globalState.type) {
      case ComplaintType.BrokenLink:
        sendComplaint(
          globalState.type,
          globalState.postId,
          "Niedziałający link został zgłoszony",
        );
        setGlobalState({ ...globalState, showComplaintDrawer: false });
        break;
      case ComplaintType.Other:
        setState({
          ...state,
          askLink: true,
          askDescription: true,
          title: "Inny błąd lub problem",
          successMessage: "Błąd został zgłoszony",
        });
        setGlobalState({ ...globalState, showComplaintDrawer: true });
        break;
      case ComplaintType.ImproperlyLinked:
        setState({
          ...state,
          askLink: true,
          askDescription: true,
          title: "Spółka jest błędnie przypisana",
          successMessage: "Błędne przypisanie zostało zgłoszone",
        });
        setGlobalState({ ...globalState, showComplaintDrawer: true });
        break;
      case ComplaintType.WorthlessPost:
        setState({
          ...state,
          askLink: false,
          askDescription: true,
          title: "Artykuł jest bezwartościowy",
          successMessage: "Bezwartościowy artykuł został zgłoszony",
        });
        setGlobalState({ ...globalState, showComplaintDrawer: true });
        break;
    }
  }, [globalState.showComplaintDrawer]);

  const sendComplaint = (
    type: ComplaintType,
    postId: number,
    successMessage: string,
    content?: string,
    linkId?: number,
  ) => {
    (async () => {
      try {
        await api.sendComplaint(type, content, postId, linkId);
        setTimeout(() => {
          setNotification(
            successMessage,
            NotificationType.Success,
            "Dziękujemy!",
          );
        }, 300);
      } catch (e) {
        console.log(e);
      }
    })();
  };

  const handleSend = () => {
    sendComplaint(
      globalState.type,
      globalState.postId,
      state.successMessage,
      state.description,
      state.linkId,
    );
    handleClose();
  };

  const handleClose = () => {
    setGlobalState({ ...globalState, showComplaintDrawer: false });
  };

  return (
    <Dialog
      open={globalState.showComplaintDrawer}
      onClose={handleClose}
      className="relative z-10"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                        {state.title}
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={handleClose}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200">
                        <div className="space-y-6 pb-5 pt-6">
                          {state.askDescription && (
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Co dokładnie stanowi problem?
                              </label>
                              <div className="mt-2">
                                <textarea
                                  id="description"
                                  name="description"
                                  rows={4}
                                  className="block w-full rounded-md border-0 px-2.5 py-1.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  defaultValue={""}
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      description: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {state.askLink && (
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Których spółek dotyczy problem?
                              </label>
                              <div className="mt-2">
                                <input
                                  id="project-name"
                                  name="project-name"
                                  type="text"
                                  className="block w-full rounded-md border-0 px-2.5 py-1.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                  >
                    Anuluj
                  </button>
                  <button
                    onClick={handleSend}
                    className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Zgłoś problem
                  </button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
