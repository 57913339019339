import { useRecoilState } from "recoil";
import { NotificationType } from "../enums/notification-type";
import { globalAtom } from "../atoms/globalAtom";
import { UserNotification } from "../entities/user-notification";

export const useNotifications = function () {
  const [globalState, setGlobalState] = useRecoilState(globalAtom);
  const setNotification = (
    title: string,
    type: NotificationType,
    description?: string,
  ) => {
    const notification = {
      title,
      description: description || "",
      type,
    };

    setGlobalState({
      ...globalState,
      notification: notification,
    });

    setTimeout(() => {
      setGlobalState({
        ...globalState,
        notification: {} as UserNotification,
      });
    }, 3000);
  };

  return { setNotification };
};
