import { useState, useEffect } from "react";

export const useLocalStorage = function <T>(key: string, initialValue: T) {
  const getInitialValue = () => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      return JSON.parse(storedValue);
    }
    return initialValue;
  };

  const [storage, setStorage] = useState<T>(getInitialValue);
  const json = JSON.stringify(storage);

  useEffect(() => {
    localStorage.setItem(key, json);
  }, [key, json]);

  return { storage, setStorage };
};
