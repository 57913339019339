import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Post } from "../entities/post";
import { Tooltip } from "./Tooltip";
import { intersperse } from "../helpers/intersperse";
import { ComplaintType } from "../enums/complaint-type";
import { api } from "../api";
import { ReactElement, useEffect, useState } from "react";
import { WideLoader } from "./WideLoader";
import { ComplaintDrawer } from "./ComplaintDrawer";
import { useRecoilState } from "recoil";
import { complaintDrawerAtom } from "../atoms/complaintDrawerAtom";

export const Posts = (props: {
  companyIds: number[];
  showCompanyNames: boolean;
  children: ReactElement;
}) => {
  const [postsState, setPostsState] = useRecoilState(complaintDrawerAtom);
  const [state, setState] = useState({
    isLoading: false,
    hasNextPage: false,
    page: 1,
    posts: [] as Post[],
  });

  const loadNextPage = () => {
    setState({ ...state, isLoading: true });

    (async () => {
      try {
        const posts = await api.getPosts(props.companyIds, state.page + 1);
        setState({
          isLoading: false,
          hasNextPage: posts.hasNextPage,
          posts: [...state.posts, ...posts.items],
          page: posts.pageNumber,
        });
      } catch {
        setState({ ...state, isLoading: false });
      }
    })();
  };

  useEffect(() => {
    (async () => {
      try {
        const posts = await api.getPosts(props.companyIds, 1);
        setState({
          isLoading: false,
          hasNextPage: posts.hasNextPage,
          posts: posts.items,
          page: posts.pageNumber,
        });
      } catch {
        setState({ ...state, isLoading: false });
      }
    })();
  }, [props.companyIds.join(",")]);

  const handleComplaint = (type: ComplaintType, postId: number) => {
    setPostsState({ ...postsState, showComplaintDrawer: true, type, postId });
  };

  return (
    <>
      <ul className="divide-y divide-gray-100">
        {state.posts.map((post) => (
          <li
            key={post.id}
            className="flex items-center justify-between gap-x-6 py-5"
          >
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-12">
                <Tooltip message={post.sourceName} className="top-11">
                  <img
                    alt={post.sourceIcon}
                    src={`/icons/${post.sourceIcon}`}
                    className="h-12 w-12 flex-none rounded-full bg-gray-50"
                  />
                </Tooltip>
              </div>
              <div>
                <div className="flex items-start gap-x-3">
                  <a
                    href={post.url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    {post.title}
                  </a>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p>
                    {post.description?.length > 404
                      ? `${post.description.substring(0, 404)} ...`
                      : post.description}
                  </p>
                </div>
                <div className="text-black-500 mt-1 flex items-center gap-x-2 text-xs leading-5">
                  <p className="whitespace-nowrap">
                    <time dateTime={post.published}>
                      {new Date(post.published).toLocaleDateString("pl-PL")}
                    </time>
                  </p>
                  {props.showCompanyNames && (
                    <>
                      <svg
                        viewBox="0 0 2 2"
                        className="h-0.5 w-0.5 fill-current"
                      >
                        <circle r={1} cx={1} cy={1} />
                      </svg>
                      <p className="truncate">
                        {intersperse(
                          post.companies.map((company) => (
                            <a
                              key={company.ticker}
                              href={`/c/${company.ticker}`}
                            >
                              {company.name}
                            </a>
                          )),
                          <>, </>,
                        )}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <Menu as="div" className="relative flex-none">
                <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <span className="sr-only">Otwórz opcje</span>
                  <EllipsisVerticalIcon
                    aria-hidden="true"
                    className="h-5 w-5"
                  />
                </MenuButton>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem>
                    <a
                      onClick={() =>
                        handleComplaint(ComplaintType.ImproperlyLinked, post.id)
                      }
                      className="block cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                    >
                      Spółka jest błędnie przypisana
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      onClick={() =>
                        handleComplaint(ComplaintType.BrokenLink, post.id)
                      }
                      className="block cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                    >
                      Link do strony nie działa
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      onClick={() =>
                        handleComplaint(ComplaintType.WorthlessPost, post.id)
                      }
                      className="block cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                    >
                      Artykuł jest bezwartościowy
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      onClick={() =>
                        handleComplaint(ComplaintType.Other, post.id)
                      }
                      className="block cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                    >
                      Inny błąd lub problem
                    </a>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </li>
        ))}
      </ul>
      {state.isLoading && <WideLoader />}
      {!state.isLoading && state.posts.length === 0 && props.children}
      {state.hasNextPage && (
        <div className="mt-5 w-full text-center">
          <button
            type="button"
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={loadNextPage}
          >
            Wczytaj starsze
          </button>
        </div>
      )}
      <ComplaintDrawer />
    </>
  );
};
