import { atom } from "recoil";
import { ComplaintType } from "../enums/complaint-type";

export const complaintDrawerAtom = atom({
  key: "complaintDrawerAtom",
  default: {
    showComplaintDrawer: false,
    postId: 0,
    type: ComplaintType.Other,
  },
});
