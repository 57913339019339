import { Posts } from "../../components/Posts";
import { useEffect } from "react";
import { ConfigPanel } from "./components/ConfigPanel";
import { ActionPanel } from "./components/ActionPanel";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useRecoilState } from "recoil";
import { portfolioScannerAtom } from "../../atoms/portfolioScannerAtom";
import { NoCompanies } from "./components/NoCompanies";
import { NoPosts } from "./components/NoPosts";
import { Helmet } from "react-helmet-async";

export const PortfolioScanner = () => {
  const [state, setState] = useRecoilState(portfolioScannerAtom);
  const { storage, setStorage } = useLocalStorage<number[]>(
    "selectedCompanies",
    state.companyIds,
  );

  if (!state.initialized) {
    setState({ ...state, companyIds: storage, initialized: true });
  }

  useEffect(() => {
    document.title = "Skaner wiadomości giełdowych";
  }, []);

  useEffect(() => {
    setStorage(state.companyIds);
  }, [state.companyIds.join(",")]);

  return (
    <>
      <Helmet>
        <title>Skaner wiadomości giełdowych</title>
        <meta
          name="description"
          content="Wiadomości giełdowe na temat wybranych spółek z kilkudziesięciu źródeł w tym serwisów specjalistycznych i branżowych."
        />
      </Helmet>
      <div className="w-full border-b border-gray-100 py-4">
        <div className="mx-auto flex max-w-5xl px-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="mt-1 text-2xl font-bold leading-tight tracking-tight text-gray-900">
              Skaner wiadomości
            </h1>
          </div>
          <div className="ml-auto min-w-28 text-right">
            {state.companyIds.length > 0 && <ActionPanel />}
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-5xl px-4 py-[2.125rem] sm:px-6 lg:px-8">
        {state.companyIds.length === 0 ? (
          <NoCompanies />
        ) : (
          <Posts companyIds={state.companyIds} showCompanyNames={true}>
            <NoPosts />
          </Posts>
        )}
        <ConfigPanel />
      </div>
    </>
  );
};
