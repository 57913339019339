import { axiosApi } from "./axiosConfig";
import { defineCancelApiObject } from "./axiosUtils";
import { Company } from "./entities/company";
import { PaginatedList } from "./entities/paginatedList";
import { Post } from "./entities/post";
import { ComplaintType } from "./enums/complaint-type";

export const api = {
  getPosts: async function (
    companyIds: number[],
    page = 1,
    cancel = false,
  ): Promise<PaginatedList<Post>> {
    const params = new URLSearchParams({
      pageSize: "25",
      pageNumber: page.toString(),
    });

    companyIds.forEach((id) => params.append("companyIds", id.toString()));

    const response = await axiosApi.request({
      url: `posts?${params.toString()}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getPosts.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getCompany: async function (
    ticker: string,
    cancel = false,
  ): Promise<Company> {
    const response = await axiosApi.request({
      url: `/company?ticker=${ticker}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getCompany.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data;
  },
  getCompanies: async function (
    companyIds: number[],
    cancel = false,
  ): Promise<Company[]> {
    const params = new URLSearchParams();

    companyIds.forEach((id) => params.append("companyIds", id.toString()));

    const response = await axiosApi.request({
      url: `/companies?${params.toString()}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getCompanies.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  searchCompanies: async function (
    query: string,
    cancel = true,
  ): Promise<Company[]> {
    const response = await axiosApi.request({
      url: `/search-companies?name=${query}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.searchCompanies.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
  sendComplaint: async function (
    type: ComplaintType,
    content?: string,
    postId?: number,
    linkId?: number,
    cancel = true,
  ): Promise<void> {
    const response = await axiosApi.request({
      url: `/complaints`,
      method: "POST",
      data: {
        postId,
        content,
        type,
        linkId,
      },
      signal: cancel
        ? cancelApiObject[this.sendComplaint.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    return response.data;
  },
};

export const cancelApiObject = defineCancelApiObject(api);
